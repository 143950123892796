import React, { useState, useEffect } from 'react';
import {
  AbsoluteFill,
  useCurrentFrame,
  useVideoConfig,
  spring,
  interpolate,
  Video,
  continueRender,
  delayRender,
  Audio,
} from 'remotion';
import BRoll from './BRoll';
import { fonts } from '../config/fontsConfig';

// Import dynamique de toutes les polices
const fontUrls = {
  TheBoldFont: require('../../public/fonts/theboldfont.ttf'),
  Cinzel: require('../../public/fonts/Cinzel.ttf'),
  SuperCarnival: require('../../public/fonts/SuperCarnival.ttf'),
  Arial: require('../../public/fonts/Arial.ttf'),
  Bangers: require('../../public/fonts/Bangers.ttf'),
  Cairo: require('../../public/fonts/Cairo.ttf'),
  Eurostile: require('../../public/fonts/Eurostile.ttf'),
  Fontspring: require('../../public/fonts/Fontspring.otf'),
  Komika: require('../../public/fonts/Komika.ttf'),
  MontserratBold: require('../../public/fonts/Montserrat-Bold.ttf'),
  MontserratRegular: require('../../public/fonts/Montserrat-Regular.ttf'),
  NotoSansBold: require('../../public/fonts/NotoSans-Bold.ttf'),
  OpinionPro: require('../../public/fonts/Opinion Pro.otf'),
  PoppinsRegular: require('../../public/fonts/Poppins-Regular.ttf'),
  RalewayRegular: require('../../public/fonts/Raleway-Regular.ttf'),
  RubikBold: require('../../public/fonts/Rubik-Bold.ttf'),
  Tibere: require('../../public/fonts/Tibere.ttf'),
  RealHorror: require('../../public/fonts/RealHorror-Regular.ttf'),
};

const loadCustomFonts = () => {
  const style = document.createElement('style');
  const fontFaces = Object.entries(fonts).map(([key, font]) => `
    @font-face {
      font-family: '${font.name}';
      src: url('${fontUrls[key]}') format('${font.file.endsWith('.otf') ? 'opentype' : 'truetype'}');
      font-weight: normal;
      font-style: normal;
    }
  `).join('\n');

  style.textContent = fontFaces;
  document.head.appendChild(style);
};

interface SubtitlesProps {
  data: {
    y_pos: number;
    is_bold: boolean;
    font_size: number;
    is_italic: boolean;
    text_color: string;
    font_family: string;
    shadow_size: number;
    text_format: string;
    outline_size: number;
    shadow_color: string;
    outline_color: string;
    video_background_url: string;
    animation_sub?: 'bump' | 'highlight' | 'highlight_text' | 'typewriter' | 'wave' | 'reveal' | 'fade_scale' | 'glitch' | 'zoom' | 'normal';
    highlight_color?: string;
    subtitles?: Array<{
      items: Array<{
        text: string;
        start_time: number;
        end_time: number;
      }>;
    }>;
    subtitles_url?: string;
    subtitles_json?: any;
    video_config?: {
      fps: number;
      width: number;
      height: number;
    }
    sound?: {
      url: string;
      trim_start?: number;
      trim_end?: number;
      music_volume?: number;
      original_video_volume?: number;
    };
    overlay?: {
      video_url: string;
      opacity: number;
    };
    brolls?: Array<{
      start_time: number;
      end_time: number;
      url: string;
      type: 'video' | 'gif';
      position?: 'top' | 'center' | 'bottom';
      angle?: number;
      start_sound?: string;
      end_sound?: string;
      animation_in?: 'bump_in' | 'translate_in' | 'fade_in';
      animation_out?: 'bump_out' | 'translate_out' | 'fade_out';
      size?: number;
    }>;
    media_items: Array<{
      type: string;
      url: string;
      duration_in_seconds: number;
    }>;
  };
}

const WordGroup = ({ words, startTimes, endTimes, style, text_format }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  // Style pour l'animation du curseur
  const cursorStyle = `
    @keyframes blink {
      0%, 100% { opacity: 1; }
      50% { opacity: 0; }
    }
  `;

  // Style pour l'animation glitch
  const glitchKeyframes = `
    @keyframes glitch-anim {
      0% {
        clip-path: inset(40% 0 61% 0);
        transform: translate(-2px, 2px);
      }
      20% {
        clip-path: inset(92% 0 1% 0);
        transform: translate(1px, -3px);
      }
      40% {
        clip-path: inset(43% 0 1% 0);
        transform: translate(-1px, 3px);
      }
      60% {
        clip-path: inset(25% 0 58% 0);
        transform: translate(3px, 1px);
      }
      80% {
        clip-path: inset(54% 0 7% 0);
        transform: translate(-3px, -2px);
      }
      100% {
        clip-path: inset(58% 0 43% 0);
        transform: translate(2px, 2px);
      }
    }

    @keyframes glitch-anim-2 {
      0% {
        clip-path: inset(25% 0 58% 0);
        transform: translate(2px, -2px);
      }
      20% {
        clip-path: inset(54% 0 7% 0);
        transform: translate(-1px, 3px);
      }
      40% {
        clip-path: inset(58% 0 43% 0);
        transform: translate(1px, -1px);
      }
      60% {
        clip-path: inset(40% 0 61% 0);
        transform: translate(-2px, 2px);
      }
      80% {
        clip-path: inset(92% 0 1% 0);
        transform: translate(3px, -3px);
      }
      100% {
        clip-path: inset(43% 0 1% 0);
        transform: translate(-2px, 1px);
      }
    }
  `;

  // Ajouter les styles au head une seule fois
  React.useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = cursorStyle + glitchKeyframes;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Trouver le dernier timing de fin du groupe
  const groupEndTime = Math.max(...endTimes);
  const groupEndFrame = Math.floor(groupEndTime * fps);
  const groupStartTime = Math.min(...startTimes);
  const groupStartFrame = Math.floor(groupStartTime * fps);

  // Ne rien rendre si nous sommes avant le début ou après la fin du groupe
  if (frame > groupEndFrame || frame < groupStartFrame) return null;

  const isHighlightAnimation = style.animation_sub === 'highlight' || style.animation_sub === 'highlight_text';
  const isHighlightBackground = style.animation_sub === 'highlight';
  const isHighlightText = style.animation_sub === 'highlight_text';

  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '85%',
      bottom: `${style.bottom}`,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: style.fontSize,
      fontWeight: style.fontWeight,
      fontStyle: style.fontStyle,
      WebkitTextStroke: style.WebkitTextStroke,
      textShadow: style.textShadow,
      color: style.color,
      fontFamily: style.fontFamily,
      lineHeight: '1.2',
      padding: '10px',
      textAlign: 'center'
    }}>
      {words.map((word, index) => {
        const startFrame = Math.floor(startTimes[index] * fps);
        const endFrame = Math.floor(endTimes[index] * fps);
        
        if (!isHighlightAnimation) {
          // Animations originales et nouvelles
          if (frame < startFrame) return null;

          const progress = spring({
            frame: frame - startFrame,
            fps,
            config: {
              damping: 100,
              stiffness: 300,
              mass: 0.5
            }
          });

          const formattedWord = text_format === 'uppercase' 
            ? word.toUpperCase() 
            : text_format === 'lowercase' 
            ? word.toLowerCase() 
            : text_format === 'capitalize'
            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            : word;

          // Calculer les animations spécifiques
          let animationStyle = {};
          
          switch (style.animation_sub) {
            case 'bump':
              const bumpProgress = spring({
                frame: frame - startFrame,
                fps,
                config: {
                  damping: 12,
                  stiffness: 200,
                  mass: 0.8
                }
              });

              // Créer une oscillation qui commence à 1.2 et se stabilise à 1
              const scaleValue = 1 + (0.2 * Math.sin(bumpProgress * Math.PI));
              
              animationStyle = {
                opacity: progress,
                transform: `scale(${scaleValue}) translateY(${(1 - bumpProgress) * -10}px)`,
              };
              break;

            case 'typewriter':
              // Effet machine à écrire
              const charCount = Math.ceil(progress * formattedWord.length);
              const visibleText = formattedWord.slice(0, charCount);
              return (
                <span
                  key={index}
                  style={{
                    display: 'inline-block',
                    margin: '0 0.2em',
                    position: 'relative',
                  }}
                >
                  {visibleText}
                  {charCount < formattedWord.length && (
                    <span
                      style={{
                        display: 'inline-block',
                        width: '2px',
                        height: '1em',
                        backgroundColor: style.color,
                        verticalAlign: 'middle',
                        animation: 'blink 1s infinite',
                      }}
                    />
                  )}
                </span>
              );

            case 'wave':
              // Effet wave avec un délai par lettre (encore plus accéléré)
              return (
                <span key={index} style={{ display: 'inline-block', margin: '0 0.2em' }}>
                  {formattedWord.split('').map((char, charIndex) => {
                    const charDelay = charIndex * 0.5; // Réduire encore plus le délai pour accélérer
                    const charProgress = spring({
                      frame: frame - startFrame - charDelay,
                      fps,
                      config: {
                        damping: 100,
                        stiffness: 300,
                        mass: 0.5
                      }
                    });

                    return (
                      <span
                        key={charIndex}
                        style={{
                          display: 'inline-block',
                          opacity: charProgress,
                          transform: `translateY(${Math.sin(charProgress * Math.PI) * -10}px) scale(${charProgress})`,
                        }}
                      >
                        {char}
                      </span>
                    );
                  })}
                </span>
              );

            case 'reveal':
              // Effet de révélation progressive (ajusté pour éviter de couper les mots)
              return (
                <span
                  key={index}
                  style={{
                    display: 'inline-block',
                    margin: '0 0.2em',
                    position: 'relative',
                    overflow: 'hidden',
                    width: 'auto', // Assurer que la largeur s'ajuste au contenu
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      transform: `translateX(${(1 - progress) * 100}%)`,
                    }}
                  >
                    {formattedWord}
                  </span>
                </span>
              );

            case 'fade_scale':
              // Effet de fondu avec scale
              animationStyle = {
                opacity: progress,
                transform: `scale(${0.5 + progress * 0.5})`,
              };
              break;

            case 'glitch':
              // Effet glitch
              const glitchProgress = spring({
                frame: frame - startFrame,
                fps,
                config: {
                  damping: 100,
                  stiffness: 300,
                  mass: 0.5
                }
              });

              // Fonction pour créer un texte glitché
              const createGlitchedText = (text) => {
                const letters = text.split('');
                const glitchedLetters = letters.map((letter, i) => {
                  const offset = Math.sin((frame + i) * 0.5) * 3;
                  const randomY = Math.sin((frame + i) * 0.3) * 2;
                  return (
                    <span
                      key={i}
                      style={{
                        display: 'inline-block',
                        transform: `translate(${offset}px, ${randomY}px)`,
                        position: 'relative',
                      }}
                    >
                      {letter}
                    </span>
                  );
                });
                return glitchedLetters;
              };

              // Créer des versions glitchées aléatoires du texte
              const shouldGlitch = frame % 4 === 0;
              const glitchedText = shouldGlitch 
                ? formattedWord.split('').map(char => 
                    Math.random() > 0.7 ? String.fromCharCode(Math.random() * 26 + 65) : char
                  ).join('')
                : formattedWord;

              return (
                <span
                  key={index}
                  style={{
                    display: 'inline-block',
                    margin: '0 0.2em',
                    position: 'relative',
                    opacity: glitchProgress,
                  }}
                >
                  {/* Texte principal avec lettres décalées */}
                  <span style={{ position: 'relative' }}>
                    {createGlitchedText(formattedWord)}
                  </span>
                  
                  {/* Couche glitch 1 */}
                  <span
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      color: shouldGlitch ? 'transparent' : style.color,
                      textShadow: `${Math.random() * 3 - 1.5}px ${Math.random() * 3 - 1.5}px rgba(0, 255, 0, 0.9)`,
                      transform: `translate(${Math.random() * 4 - 2}px, ${Math.random() * 4 - 2}px)`,
                      clipPath: shouldGlitch ? 'inset(0 0 50% 0)' : 'none',
                      zIndex: 1,
                      mixBlendMode: 'screen',
                    }}
                  >
                    {createGlitchedText(glitchedText)}
                  </span>
                  
                  {/* Couche glitch 2 */}
                  <span
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      color: shouldGlitch ? 'transparent' : style.color,
                      textShadow: `${Math.random() * 3 - 1.5}px ${Math.random() * 3 - 1.5}px rgba(0, 0, 0, 0.95)`,
                      transform: `translate(${Math.random() * 4 - 2}px, ${Math.random() * 4 - 2}px)`,
                      clipPath: shouldGlitch ? 'inset(50% 0 0 0)' : 'none',
                      zIndex: 2,
                      mixBlendMode: 'multiply',
                    }}
                  >
                    {createGlitchedText(glitchedText)}
                  </span>
                </span>
              );

            case 'zoom':
              // Effet de zoom progressif
              animationStyle = {
                opacity: progress,
                transform: `scale(${0.5 + progress * 0.5})`,
              };
              break;

            case 'static':
              // Afficher les mots sans animation
              animationStyle = {
                opacity: 1,
                transform: 'none',
              };
              break;

            default:
              animationStyle = {
                opacity: progress,
                transform: `scale(${progress})`,
              };
          }

          // Rendu par défaut pour bump et fade_scale
          if (!['typewriter', 'wave', 'reveal', 'glitch'].includes(style.animation_sub)) {
            return (
              <span
                key={index}
                style={{
                  display: 'inline-block',
                  margin: '0 0.2em',
                  ...animationStyle,
                }}
              >
                {formattedWord}
              </span>
            );
          }
        } else {
          // Animation highlight
          const formattedWord = text_format === 'uppercase' 
            ? word.toUpperCase() 
            : text_format === 'lowercase' 
            ? word.toLowerCase() 
            : text_format === 'capitalize'
            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            : word;

          const currentWordStartFrame = Math.floor(startTimes[index] * fps);
          const currentWordEndFrame = Math.floor(endTimes[index] * fps);
          
          // Animation d'entrée du highlight
          const highlightProgress = frame >= currentWordStartFrame && frame <= currentWordEndFrame ? spring({
            frame: frame - currentWordStartFrame,
            fps,
            config: {
              damping: 100,
              stiffness: 300,
              mass: 0.5
            }
          }) : 0;

          // Ne rendre le mot que s'il est dans le groupe
          if (frame < groupStartFrame || frame > groupEndFrame) return null;

          const highlightColor = style.highlight_color?.includes('gradient') 
            ? style.highlight_color 
            : style.highlight_color || '#8A2BE2';

          return (
            <span
              key={index}
              style={{
                display: 'inline-block',
                margin: '0 0.2em',
                position: 'relative',
                padding: '4px 4px -4px 4px',
                verticalAlign: 'middle',
                transform: 'translateY(-2px)',
                color: isHighlightText && highlightProgress > 0 ? highlightColor : style.color,
              }}
            >
              {isHighlightBackground && (
                <span
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    right: 0,
                    bottom: 0,
                    height: '100%',
                    width: '110%',
                    transform: `translateY(-50%)`,
                    background: highlightColor,
                    opacity: highlightProgress,
                    borderRadius: '3px',
                    transition: 'transform 0.2s ease-out',
                    zIndex: -1,
                  }}
                />
              )}
              {formattedWord}
            </span>
          );
        }
      })}
    </div>
  );
};

export const calculateMetadata = (props) => {
  const duration = props?.props?.media_items?.[0]?.duration_in_seconds;
  
  return {
    durationInFrames: Math.ceil(duration * 30),
    fps: 30,
    width: 1080,
    height: 1920,
  };
};

export const RemotionSubtitles: React.FC<SubtitlesProps> = ( data ) => {
  const [handle] = useState(() => delayRender());
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const frame = useCurrentFrame();

  const style = {
    fontSize: `${data.font_size}px`,
    fontWeight: data.is_bold ? 'bold' : 'normal',
    fontStyle: data.is_italic ? 'italic' : 'normal',
    WebkitTextStroke: `${data.outline_size}px ${data.outline_color}`,
    textShadow: data.text_shadow 
      ? data.text_shadow 
      : data.shadow_size > 0 ? `${data.shadow_size}px ${data.shadow_size}px ${data.shadow_color}` : 'none',
    color: data.text_color,
    position: 'absolute' as const,
    width: '100%',
    textAlign: 'center' as const,
    bottom: `${data.y_pos}%`,
    fontFamily: fonts[data.font_family]?.name || data.font_family,
    lineHeight: '1.2',
    padding: '10px',
    animation_sub: data.animation_sub,
    highlight_color: data.highlight_color
  };

  useEffect(() => {
    loadCustomFonts();
    setFontsLoaded(true);

    // Précharger la vidéo de fond et l'overlay
    const preloadAssets = async () => {
      const assets = [
        data.video_background_url,
        data.overlay?.video_url,
      ].filter(Boolean);

      try {
        await Promise.all(
          assets.map(
            (url) =>
              new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.src = url;
                video.onloadeddata = resolve;
                video.onerror = reject;
              })
          )
        );
        setAssetsLoaded(true);
        continueRender(handle);
      } catch (err) {
        console.error('Error preloading assets:', err);
        setAssetsLoaded(true);
        continueRender(handle);
      }
    };

    preloadAssets();

    const style = document.createElement('style');
    style.textContent = `
      #server-disconnected-overlay, .css-reset {
        display: none !important;
      }
    `;
    document.head.appendChild(style);
  
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Ne pas rendre tant que les assets ne sont pas chargés
  if (!fontsLoaded || !assetsLoaded || frame === 0) return null;

  return (
    <AbsoluteFill>
      {data.video_background_url && (
        <Video 
          src={data.video_background_url} 
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          volume={data.sound?.original_video_volume ?? 1}
        />
      )}

      {/* Render Brolls */}
      <div style={{ position: 'relative', width: '100%', height: '100%', zIndex: 1 }}>
        {data.brolls?.map((broll, index) => (
          <BRoll
            key={index}
            start={broll.start_time}
            end={broll.end_time}
            url={broll.url}
            type={broll.type}
            position={broll.position}
            size={broll.size}
            angle={broll.angle}
            speed={1}
            start_sound={broll.start_sound}
            end_sound={broll.end_sound}
            font_family={data.font_family}
            animation_in={broll.animation_in}
            animation_out={broll.animation_out}
          />
        ))}
      </div>

      {/* Render Overlay Video if present */}
      {data.overlay && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: data.overlay.opacity }}>
          <Video
            src={data.overlay.video_url}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            loop
          />
        </div>
      )}
      
      {data.sound && (
        <Audio
          src={data.sound.url}
          volume={data.sound.music_volume ?? 0.5}
          startFrom={(data.sound.trim_start * 30)}
        />
      )}

      <AbsoluteFill style={{ zIndex: 1 }}>
        {data.subtitles?.map((group, groupIndex) => (
          <WordGroup
            key={groupIndex}
            words={group.items.map(item => item.text)}
            startTimes={group.items.map(item => parseFloat(item.start_time.toString() || '0'))}
            endTimes={group.items.map(item => parseFloat(item.end_time.toString() || '0'))}
            style={style}
            text_format={data.text_format}
          />
        ))}
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
