import React from 'react';
import {
  useVideoConfig,
  Video,
  Sequence,
  Audio,
  useCurrentFrame,
  interpolate,
  Easing,
  spring,
} from 'remotion';
import { Gif } from '@remotion/gif';

const GifComponent: React.FC<{
  src: string;
  style: React.CSSProperties;
}> = ({ src, style }) => {
  return (
    <div style={{
      ...style,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Gif
        src={src}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

const BRoll: React.FC<{ 
  start: number; 
  end: number; 
  url: string; 
  speed: number;
  type: 'video' | 'gif';
  position?: number;
  size?: number;
  angle?: number; 
  start_sound?: string;
  end_sound?: string;
  font_family: string;
  animation_in?: 'bump_in' | 'translate_in' | 'fade_in';
  animation_out?: 'bump_out' | 'translate_out' | 'fade_out';
}> = ({ start, end, url, speed, type, position = 0, size, angle = 0, start_sound, end_sound, font_family, animation_in, animation_out }) => {
  const { fps } = useVideoConfig();
  const frame = useCurrentFrame();
  
  const startFrame = Math.floor(start * fps);
  const endFrame = Math.floor(end * fps);
  const duration = Math.floor((endFrame - startFrame) / speed);
  const animationDuration = Math.floor(0.1 * fps); // 0.1 seconds for all quick animations

  const getAnimation = () => {
    if (frame < startFrame || frame > startFrame + duration) return { translate: 0, opacity: 0, scale: 1 };

    const progress = (frame - startFrame) / duration;
    let translate = 0;
    let opacity = 1;
    let scale = 1;

    if (progress <= 0.5) {
      const animationProgress = Math.min((frame - startFrame) / animationDuration, 1);
      if (animation_in === 'bump_in') {
        scale = spring({
          frame: frame - startFrame,
          fps,
          from: 0,
          to: 0.9,
          config: {
            damping: 10,
            stiffness: 100,
          },
        });
      } else if (animation_in === 'translate_in') {
        translate = interpolate(animationProgress, [0, 1], [500, 0], {
          extrapolateRight: 'clamp',
          easing: Easing.easeOut,
        });
      } else if (animation_in === 'fade_in') {
        opacity = interpolate(animationProgress, [0, 1], [0, 1], {
          extrapolateRight: 'clamp',
          easing: Easing.linear,
        });
      }
    } else {
      const animationProgress = Math.max(0, Math.min((startFrame + duration - frame) / animationDuration, 1));
      if (animation_out === 'bump_out') {
        scale = spring({
          frame: startFrame + duration - frame,
          fps,
          from: 0,
          to: 0.9,
          config: {
            damping: 10,
            stiffness: 100,
          },
        });
      } else if (animation_out === 'translate_out') {
        translate = interpolate(animationProgress, [0, 1], [500, 0], {
          extrapolateLeft: 'clamp',
          easing: Easing.easeIn,
        });
      } else if (animation_out === 'fade_out') {
        opacity = interpolate(animationProgress, [0, 1], [0, 1], {
          extrapolateLeft: 'clamp',
          easing: Easing.linear,
        });
      }
    }

    return { translate, opacity, scale };
  };

  const getStyle = () => {
    const { scale, opacity, translate } = getAnimation();
    const baseTransform = `rotate(${angle}deg) scale(${scale})`;
    const animationTransform = `translateX(${translate}px)`;

    const baseStyle = {
      opacity,
      position: 'absolute' as const,
      left: '50%',
      transform: `${baseTransform} translateX(-50%) ${animationTransform}`,
      fontFamily: font_family,
    };

    const fullscreenStyle = {
      width: '100%',
      height: '100%',
      objectFit: 'cover' as const,
      left: 0,
      transform: `${baseTransform} ${animationTransform}`,
    };

    if (position === 0) {
      return {
        ...baseStyle,
        ...fullscreenStyle,
      };
    }

    return {
      ...baseStyle,
      top: `${position * 10}px`,
      width: `${400 * size}px`,
      height: `${400 * size}px`,
      backgroundColor: 'rgba(0, 0, 0, 1)',
    };
  };

  const style = getStyle();

  return (
    <>
      {start_sound && (
        <Sequence from={startFrame}>
          <Audio src={start_sound} volume={0.2} />
        </Sequence>
      )}

      <Sequence from={startFrame} durationInFrames={duration}>
        {type === 'video' ? (
          <Video src={url} style={style} />
        ) : (
          <GifComponent src={url} style={style} />
        )}
      </Sequence>

      {end_sound && (
        <Sequence from={startFrame + duration - animationDuration}>
          <Audio src={end_sound} volume={0.2} />
        </Sequence>
      )}
    </>
  );
};

export default BRoll;
;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
